import React from 'react'
import styled from 'styled-components'
import Navbar from '../components/Navbar'
import { breakpoints, layout } from '../theme'
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import Login, { StyledLogin } from '../components/Login'
import { StyledProfileDropdown } from '../components/ProfileDropdown'
import Link, { navigate } from '../components/Link'
import { REGISTER } from '../util/links'

const LoginPage = props => {
  return (
    <StyledLoginPage {...props}>
      <Helmet meta={[{ name: 'robots', content: 'noindex' }]} />
      <Navbar />
      <div className="login-box">
        <h2>Connectez-vous pour acceder à vos commandes</h2>
        <Login
          onLogin={() => navigate('/')}
          onCancel={() => navigate(-1)}
          // eslint-disable-next-line no-console
          onPasswordForgot={() => navigate('/forgot-password')}
        />
        <div className="btn-link">
          ou
          <Link to={REGISTER}>Créez un compte</Link>
        </div>
      </div>
      <Footer />
    </StyledLoginPage>
  )
}

const StyledLoginPage = styled.div`
  .login-box {
    margin-top: ${layout.navbarHeight.normal}px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledProfileDropdown} {
    display: none;
  }

  h2 {
    text-align: center;
  }

  ${StyledLogin} {
    width: 100%;
    padding-top: 50px;
  }

  .btn-link {
    margin-top: 30px;
    a {
      margin-left: 10px;
      color: #fc4c4c;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export default LoginPage
