import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { TextInput, Button, StyledButton, Pulse } from '@monbanquet/crumble'
import { AuthenticationContext } from './context/AuthenticationContext'

const Login = ({ onLogin, onCancel, onPasswordForgot, ...props }) => {
  const { user, login } = useContext(AuthenticationContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState()

  return (
    <StyledLogin
      onSubmit={async e => {
        e.preventDefault()
        try {
          const u = await login(email, password)
          onLogin(u)
        } catch (err) {
          setError(`Erreur de connexion, vérifiez vos identifiants`)
        }
      }}
      {...props}
    >
      <TextInput
        className="email-input"
        label="Adresse email"
        type="email"
        autoComplete="email"
        secondary
        placeholder="mon@email.com"
        required
        autoFocus
        value={email}
        onChange={emailInput => setEmail(emailInput)}
      />
      <TextInput
        className="password-input"
        label="Mot de passe"
        type="password"
        autoComplete="password"
        secondary
        required
        value={password}
        onChange={passwordInput => setPassword(passwordInput)}
      />
      <button className="btn-text" type="button" onClick={onPasswordForgot}>
        {`J'ai oublié mon mot de passe`}
      </button>
      <div className="btns">
        <Button onClick={onCancel} className="cancel">
          Annuler
        </Button>
        <Button type="submit">
          {typeof user === 'undefined' ? <Pulse /> : `Se connecter`}
        </Button>
      </div>
      <p className="error">{error}</p>
    </StyledLogin>
  )
}

const StyledLogin = styled.form`
  max-width: 400px;

  .error {
    margin-top: 20px;
    color: #fc4c4c;
    text-align: center;
  }

  .email-input {
    margin-bottom: 30px;
  }

  .btns {
    display: flex;
  }

  ${StyledButton} {
    border-radius: 4px;
  }

  .cancel {
    background: #aaa;
    margin-right: auto;
  }

  .btn-text {
    margin: 10px 0;
    appearance: none;
    border: none;
    background: none;
    font-size: 12px;
    cursor: pointer;
    color: #999;
    transition: all 50ms ease-in-out;
    &:hover {
      text-decoration: underline;
      color: #bc8e15;
    }
  }
`

export default Login
export { StyledLogin }
